import React, { useEffect } from "react";

const SitemapXML = () => {
  const pages = [
    { loc: "https://www.clinixsphere.in/", priority: "1.00" },
    { loc: "https://www.clinixsphere.in/about", priority: "1.00" },
    { loc: "https://www.clinixsphere.in/features", priority: "0.80" },
    { loc: "https://www.clinixsphere.in/contact", priority: "0.80" },
  ];

  useEffect(() => {
    const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>';
    const urlsetOpenTag =
      '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">';
    const urlsetCloseTag = "</urlset>";

    const urls = pages
      .map(
        (page) => `
      <url>
        <loc>${page.loc}</loc>
        <lastmod>${new Date().toISOString()}</lastmod>
        <priority>${page.priority}</priority>
      </url>
    `
      )
      .join("");

    const sitemap = `${xmlHeader}${urlsetOpenTag}${urls}${urlsetCloseTag}`;

    const blob = new Blob([sitemap], { type: "application/xml" });
    const url = URL.createObjectURL(blob);
    window.location.href = url;
  }, [pages]);

  return null;
};

export default SitemapXML;
