import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./header.css";
import { useDispatch, useSelector } from "react-redux";
import useApiRequest from "../../hooks/apiRequest";
import Cookies from "js-cookie";
import { ErrorToast, SuccessToast } from "../common/toast/toast";
import Loader from "../common/Loader";
import { signOut } from "../../redux/auth/authSlice";
import { jwtDecode } from "jwt-decode";

const Header = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const { apiRequest, loading } = useApiRequest();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false); // State for scroll behavior
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu toggle
  const navRef = useRef(null); // Reference for the nav menu
  const menuToggleRef = useRef(null); // Reference for the menu toggle button
  const location = useLocation(); // Location hook to determine active link

  // Handle scroll behavior
  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  // Toggle the menu open/close when the menu icon is clicked
  const handleMenuToggle = () => {
    setIsMenuOpen((prevState) => !prevState); // Toggle the menu state
  };

  // Close the menu when clicking outside of it
  const handleClickOutside = (event) => {
    if (
      navRef.current &&
      !navRef.current.contains(event.target) &&
      menuToggleRef.current &&
      !menuToggleRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false); // Close the menu if the click is outside the menu and toggle button
    }
  };

  // Add event listeners for scrolling and clicks outside the menu
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Determine if a link is active based on the current route
  const isActiveLink = (path) => location.pathname === path;

  //check token validation
  const token = Cookies.get("clinix-auth-token");
  const isTokenValid = (token) => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      return decoded.exp > Date.now() / 1000; // Token is valid if exp is in the future
    } catch (error) {
      console.error("Token decoding failed:", error);
      return false;
    }
  };

  // Signout function
  const handleSignOut = async () => {
    if (!token) {
      ErrorToast("No active session found.");
      return;
    }

    try {
      // Prepare data with the token for the API request
      const data = { token };

      // Make the API request to log out
      const res = await apiRequest("POST", "/userMain/logout", data);

      // Dispatch the signOut action to update the state
      dispatch(signOut());

      // Remove the token from cookies
      Cookies.remove("clinix-auth-token");

      // Show success message
      SuccessToast(res.message || "Logged out successfully.");

      // Optionally, navigate to the login or home page after logging out
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
      ErrorToast(error.response?.data?.message || "Error in logging out.");
    }
  };

  return (
    <header className={`header ${scrolled ? "scrolled" : ""}`}>
      {/* Company logo */}
      <Link to="/" className="logo">
        <img src="/logo.svg" alt="LOGO" />
        Clinix Sphere
      </Link>

      {/* Navigation bar */}
      <nav className={`nav ${isMenuOpen ? "active" : ""}`} ref={navRef}>
        <Link to="/" className={isActiveLink("/") ? "active-link" : ""}>
          Home
        </Link>
        <Link
          to="/contact"
          className={isActiveLink("/contact") ? "active-link" : ""}
        >
          Contact
        </Link>
        <Link to="/blog" className={isActiveLink("/blog") ? "active-link" : ""}>
          Blog
        </Link>
      </nav>
      {currentUser && isTokenValid(token) ? (
        <button
          type="button"
          className="Sign-In"
          onClick={handleSignOut}
          disabled={loading}
        >
          {loading ? <Loader marginY="1" /> : <p>SIGN OUT</p>}
        </button>
      ) : (
        <button
          type="button"
          className="Sign-In"
          onClick={() => {
            navigate("/auth/signin");
          }}
        >
          <p>SIGN IN</p>
        </button>
      )}

      {/* Menu toggle button for mobile view */}
      <div
        className={`menu-toggle`}
        onClick={handleMenuToggle}
        ref={menuToggleRef} // Add ref to the toggle button
      >
        <span></span>
      </div>
    </header>
  );
};

export default Header;
