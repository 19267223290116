import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import useApiRequest from "../../hooks/apiRequest";

import { ErrorToast } from "../../components/common/toast/toast";

import { Pagination } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { setLatestBlogs } from "../../redux/blog/blogSlice";
import Loader from "../../components/common/Loader";
import { formatDate } from "../../components/common/formatDate";
import { isUserAdmin } from "../../components/common/isUserAdmin";

const categories = [
  {
    name: "Healthcare Technology",
    value: 1,
  },
  { name: "Inventory and HR management", value: 2 },
  { name: "Telemedicine Innovations", value: 3 },
  { name: "Digital Transformation in Healthcare", value: 4 },
  { name: "Operational Efficiency", value: 5 },
  { name: "Patient Management", value: 6 },
];

const Blog = () => {
  const navigate = useNavigate();
  const { loading, apiRequest } = useApiRequest();
  const [page, setPage] = useState(1);
  const [blogs, setBlogs] = useState([]);

  const [categoryId, setCategoryId] = useState(null);
  // const [latestBlogs, setLatestBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState(1);

  const { latestBlogs } = useSelector((state) => state.blog);
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onPageChange = (page) => {
    setPage(page);
  };
  //Fetch blogs
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        let res;

        // No category and no search query
        if (!categoryId && !searchQuery) {
          res = await apiRequest("GET", `/blog/get-blog?page=${page}`);
        }
        // When search query exists
        else if (searchQuery && !categoryId) {
          setPage(1);
          res = await apiRequest(
            "GET",
            `/blog/search-blog?page=${page}&searchInput=${searchQuery}`
          );
        } else if (searchQuery && categoryId) {
          setPage(1);
          res = await apiRequest(
            "GET",
            `/blog/search-blog?page=${page}&searchInput=${searchQuery}&categoryId=${categoryId}`
          );
        }

        // When category exists
        else if (categoryId) {
          setPage(1);
          res = await apiRequest(
            "GET",
            `/blog/get-blog?page=${page}&categoryId=${categoryId}`
          );
        }

        const Blogs = res?.data?.blogs;
        const LatestBlogs = res?.data?.latestBlogs;
        const totalBlogs = res?.data?.totalBlogs;
        setNumberOfPages(Math.ceil(totalBlogs / 6));

        if (!searchQuery && !categoryId) {
          dispatch(setLatestBlogs(LatestBlogs));
        }
        if (Blogs) {
          setBlogs(Blogs);
        }
      } catch (error) {
        console.error("Error in fetching blogs: ", error);
        ErrorToast(error.message || "Error fetching blogs");
      }
    };

    fetchBlogs();
  }, [page, categoryId, searchQuery]);

  // Category Toggle Handler
  const handleCategoryIdChange = (selectedCategoryId) => {
    if (categoryId === selectedCategoryId) {
      setCategoryId(null); // Deselect if the same category is clicked
    } else {
      setCategoryId(selectedCategoryId); // Select new category
    }
    setPage(1); // Reset to the first page
    setBlogs([]); // Clear existing blogs
  };
  //read more
  const handleReadMore = (blogId) => {
    navigate(`/blog/readblog/${blogId}`);
  };

  //remove styles from html
  const stripHTMLTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  //to find category of blog by its id
  const categoryById = (categoryId) => {
    return categories.find((category) => category.value === categoryId);
  };

  //handle search basically set the search query so that blogs can be fetched again according to query
  const handleSearch = (e) => {
    e.preventDefault(); // Prevent page reload

    // Access the input value directly
    const searchInput = e.target.elements.searchQuery.value.trim();

    setSearchQuery(searchInput); // Optionally store it in the state
  };

  return (
    <div
      style={{ fontFamily: "Lato, sans-serif" }}
      className="font-lato text-[#03045E]"
    >
      <div className="h-full items-center w-full mx-auto bg-white p-[91px] pb-0">
        {/* hero section */}
        <section className="flex flex-col gap-8 mb-14">
          <h1 className="font-bold text-[54px] text-center">ClinixSphere</h1>
          <p className="text-[32px] font-normal w-[60%] text-center mx-auto">
            Explore how Clinix Sphere empowers clinics and hospitals with
            seamless digital solutions, streamlining operations and enhancing
            patient care through advanced technology.
          </p>

          {/* Search Bar */}
          <form className="mx-auto w-[90%] max-w-lg" onSubmit={handleSearch}>
            <div className="relative h-12 border border-[#03045E] rounded-lg flex items-center overflow-hidden">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                {/* <FaSearch  /> */}
                <img
                  className=" h-7"
                  src="https://res.cloudinary.com/djwfu7z21/image/upload/v1730533222/search_dmfbw1.svg"
                  alt=""
                />
                <div className="w-[1px] h-6 bg-[#03045E] ml-3"></div>
              </div>

              <input
                type="search"
                id="default-search"
                className="w-full h-full pl-16 pr-4 text-lg placeholder-[#03045E] "
                placeholder="Search the blog"
                name="searchQuery"
              />
            </div>
          </form>
        </section>
        {/* categories section  */}
        <section className=" flex flex-col gap-4 my-14">
          <h1 className="text-[36px] font-semibold flex flex-row gap-2 items-center ">
            |<span>Categories</span>
          </h1>

          <div className="flex flex-col justify-center">
            {/* Check if categories exist to render buttons */}
            {categories.length > 0 && (
              <>
                {/* Create a grid layout to display buttons for each category */}
                <div className="grid grid-cols-3 items-center gap-4 ">
                  {categories.map((item, index) => (
                    <button
                      key={item.value} // Use the category value as a unique key for React to optimize rendering
                      className={`border border-[#03045E] w-[347.92px] p-2 rounded-xl text-xl mx-auto cursor-pointer transition-colors ${
                        categoryId === item.value // Check if the current category is selected
                          ? "bg-[#03045E] text-white" // If selected, apply styles for the active state
                          : "bg-white hover:bg-[#03045E] hover:text-white" // If not selected, apply default styles and hover effects
                      }`}
                      // Call the handleCategoryIdChange function with the selected category value when clicked
                      onClick={() => handleCategoryIdChange(item.value)}
                    >
                      {item.name}{" "}
                      {/* Display the name of the category on the button */}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        </section>
        {/* blog cards  */}
        <section className="my-14">
          {/* Blog cards grid */}
          {!loading && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-14 items-center ">
              {blogs?.map((blog) => (
                <div
                  key={blog?._id}
                  className="w-[371px] h-[560px] mx-auto rounded-lg shadow-lg overflow-hidden border border-[#03045E]"
                >
                  <div className="relative">
                    <img
                      src={blog?.image}
                      alt="logo"
                      className="w-[371px] h-[280px] overflow-hidden rounded-sm rounded-br-none rounded-bl-none"
                    />
                  </div>
                  <div className="h-[1px] border border-[#03045E ]"></div>
                  <div className="px-4 py-2 text-left ">
                    <div className="w-full mx-auto ">
                      <p className="h-[1px] w-[323.12px] bg-[#CFCFCF] mt-2"></p>
                      <div className="w-full flex gap-16">
                        <img
                          src="https://png.pngtree.com/png-clipart/20230927/original/pngtree-man-avatar-image-for-profile-png-image_13001882.png"
                          alt={blog?.authorName}
                          className="rounded-full w-[50px] h-[50px]"
                        />
                        <p className="text-[12px] font-bold text-[#6C757D] mt-4">
                          By {blog?.author}
                        </p>
                        <p className=" h-[15px] text-[12px] text-[#03045E]">
                          {formatDate(blog?.createdAt)}
                        </p>
                      </div>
                      {/* Category Label */}
                      <div className=" text-[#03045E] bg-[#a6a6bb] bg-opacity-30 backdrop-blur-sm text-[12px] px-2 py-1  rounded-md  font-bold  text-center overflow-clip text-nowrap">
                        {categoryById(blog?.category).name}
                      </div>
                      <h1 className="font-medium text-[20px] text-[#03045E] min-h-[48px]">
                        {blog?.title.slice(0, 60)}
                      </h1>
                      <p className="text-[1rem] font-normal text-[#03045E] min-h-[32px]">
                        {stripHTMLTags(blog?.content.slice(0, 61))}...
                      </p>
                      <div className="w-full flex justify-center">
                        <button
                          className="w-[125px] h-[40px]  px-[11px] py-[9px] border border-[#03045E]  rounded-lg shadow-xl hover:shadow-2xl text-[#03045E]"
                          onClick={() => handleReadMore(blog?._id)}
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* loading spinner while blogs are loading  */}
          {loading ? (
            <div>
              {" "}
              <Loader />
            </div>
          ) : (
            // No blogs if no blogs are there
            <div className="flex justify-center my-8">
              {blogs?.length === 0 ? "No Blogs" : ""}
            </div>
          )}
          {/* Pagination  */}
          <div className="flex overflow-x-auto sm:justify-center">
            {blogs?.length > 0 && (
              <Pagination
                layout="pagination"
                currentPage={page}
                totalPages={numberOfPages}
                onPageChange={onPageChange}
                previousLabel=""
                nextLabel=""
                showIcons
              />
            )}
          </div>
        </section>

        {/* recently posted  */}
        <section className=" m-14 ">
          <h1 className="text-[32px] font-semibold flex flex-row gap-2 items-center mb-14 ">
            | Recently <span className="text-black">Posted</span>
          </h1>

          <div className="flex  gap-8 mx-auto">
            {latestBlogs?.slice(0, 2).map((blog) => {
              return (
                <div
                  key={blog._id}
                  className="bg-[#ffffff] border border-[#03045E] px-6 py-2 rounded-xl w-[400px] h-[600px] mx-auto  cursor-pointer hover:border"
                >
                  <div className="relative w-[350px] h-[271px] bg-[#D9D9D9] rounded-md">
                    {/* Image */}
                    <img
                      src={blog.image}
                      alt="Blog Image"
                      className="rounded-md w-full h-full object-cover"
                    />
                  </div>

                  <div className="text-left pl-2 my-2 gap-4 w-[350px] h-[250px]">
                    <div className="text-[12px] flex gap-44 pb-2">
                      <p className="font-bold text-[#03045E] ">
                        By {blog.author}
                      </p>
                      <p className="text-[#03045E] text-[12px]">
                        {formatDate(blog?.createdAt)}
                      </p>
                    </div>
                    {/* Category Label */}
                    <p className="  bg-[#e2dff1] py-[5px] px-4 rounded-md text-sm text-[#03045E] text-center">
                      {categoryById(blog?.category).name}
                    </p>

                    <h1 className="font-semibold text-[#03045E] my-2 text-2xl">
                      {blog.title.slice(0, 62)}
                    </h1>
                    <p className="text-[15px] text-[#03045E] mt-4 break-words">
                      {stripHTMLTags(blog?.content.slice(0, 140))}...
                    </p>
                    <div className="flex justify-center mt-4 ">
                      <button
                        onClick={() => {
                          handleReadMore(blog?._id);
                        }}
                        className="px-4 py-2 bg-[#fff] text-[#03045E]  border border-[#03045E] rounded-md hover:bg-[#023E8A] transition-colors"
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        {/* create blog */}
        {isUserAdmin(currentUser) && (
          <div>
            <h1 className="text-4xl font-bold flex justify-start">
              Create a Blog
            </h1>
            <div className="border border-[#03045E] h-[1px] mt-1"></div>
            <div className="border-[6px] border-[#03045E] h-[370px] w-[600px] mx-auto my-32 rounded-xl shadow-lg bg-gradient-to-br from-[#FFFFFF] to-[#E5E2FF] flex items-center justify-center">
              <button
                className="flex items-center gap-2 shadow-lg bg-white h-12 w-40 justify-center rounded-lg hover:shadow-xl transform hover:scale-105 transition duration-200"
                onClick={() => {
                  navigate("/blog/createblog"); // Navigate to create blog page
                }}
              >
                <img
                  src="https://res.cloudinary.com/djwfu7z21/image/upload/v1730533223/createblog_bnc3hq.svg"
                  alt=""
                  className="h-6 w-6"
                />
                <p className="text-[#03045E] font-medium">Create Blog</p>
              </button>
            </div>
          </div>
        )}
      </div>
      {/* custom footer  */}
      <section className="">
        <div className="flex flex-col gap-8 items-start p-14 bg-[rgb(243,240,240)]">
          <h1 className="text-[#1C5CFF] text-[20px] ">Stay up to date</h1>
          <p className="text-black font-bold text-[36px]">
            Join our Newsletter
          </p>
          <div className="flex flex-row gap-4 ml-24 items-center text-center">
            <input
              className="w-[267.5px] h-[32.77px] rounded-none border-none p-2"
              id="email1"
              type="email"
              placeholder="Enter your email"
              required
            />
            <button className="w-[103.18px] h-[32.77px] bg-transparent text-[16px] font-bold border-[1px] border-gray-300 text-center">
              Get started
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
