import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import useApiRequest from "../../hooks/apiRequest.js";
import PreviewBlog from "./previewBlog.jsx";
import {
  ErrorToast,
  SuccessToast,
} from "../../components/common/toast/toast.js";
import { useSelector } from "react-redux";
import BackButton from "../../components/common/BackButton.jsx";
import ReactQuill from "react-quill";

const categories = [
  { value: 1, name: "Healthcare Technology" },
  { value: 2, name: "Inventory and HR Management" },
  { value: 3, name: "Telemedicine Innovations" },
  { value: 4, name: "Digital Transformation in Healthcare" },
  { value: 5, name: "Operational Efficiency" },
  { value: 6, name: "Patient Management" },
];

const CreateBlog = () => {
  const { loading, apiRequest } = useApiRequest();
  const { currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState(1);
  const [cancel, setCancel] = useState(false);
  const [preview, setPreview] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State to show confirmation popup

  const handlePublishClick = () => {
    setShowPopup(true); // Show confirmation popup when Publish is clicked
  };

  const handleCancelClick = () => {
    setShowPopup(true); // Show confirmation popup when Cancel is clicked
    setCancel(true);
  };

  const handleConfirm = (action) => {
    if (action === "publish") {
      setShowPopup(false);
      // Perform your publishing action here
      setTimeout(() => {}, 2000);
    } else if (action === "cancel") {
      setShowPopup(false);
      navigate("/blog/createblog");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title) {
      return ErrorToast("Title is required.");
    }
    if (!author) {
      return ErrorToast("Author is required.");
    }
    if (!content) {
      return ErrorToast("Content is required.");
    }

    // Creating formData to send to the backend, including the image file
    const formData = new FormData();
    formData.append("title", title);
    formData.append("author", author);
    formData.append("authorId", currentUser.id);
    formData.append("content", content);
    formData.append("category", category);

    // Appending the image file only if an image is selected
    if (image) {
      formData.append("image", image);
    }

    //A problem is arising can't evaluate null ie. res.data.data because when error is coming it is not redirecting to catch block.
    // By defaul category is 1, I think it should be zero which is uncategorized.

    try {
      const res = await apiRequest("POST", "/blog/create-blog", formData);
      SuccessToast(res.data.data || "Blog created successfully");
      setTimeout(() => {
        navigate("/blog");
      }, 200);
    } catch (error) {
      console.error("Error creating blog post:", error);
      ErrorToast(
        error.message ||
          error.response.data.message ||
          "Error in publishing blog!"
      );
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <>
      {preview ? (
        // Different component for preview blog, it is visible only when preview is true
        <PreviewBlog
          title={title}
          author={author}
          content={content}
          setPreview={setPreview}
          preview={preview}
          categoryId={category}
          image={image ? URL.createObjectURL(image) : ""}
        />
      ) : (
        <div>
          <div className="mx-auto p-6 bg-white rounded-lg text-[#03045E]">
            <BackButton />

            <h1 className="text-3xl font-semibold mb-8 text-left ml-20">
              Create Blog
            </h1>

            <form
              onSubmit={handleSubmit}
              className="bg-[#fafaff] p-10 w-[1362px]  min-h-screen mx-auto rounded-xl"
            >
              {/* Image Upload */}
              <div className="mb-6">
                <div
                  className={`relative border-[7px] border-[#03045E] rounded-lg p-4 flex flex-col gap-4 justify-center items-center hover:bg-gray-100 transition-all w-[939.21px] h-[361px] mx-auto shadow-lg bg-gradient-to-br from-[#FFFFFF] to-[#E5E2FF]`}
                >
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    accept="image/*"
                    className="hidden"
                    id="imageUpload"
                  />

                  {/* Change Image Button */}
                  <label
                    htmlFor="imageUpload"
                    className={`cursor-pointer py-2 px-4 rounded-lg bg-white shadow-lg hover:shadow-xl hover:scale-105 transition-all duration-500 absolute ${
                      image ? "top-4" : "top-1/2 transform -translate-y-1/2"
                    } left-1/2 transform -translate-x-1/2`}
                  >
                    <div className="flex gap-4 items-center">
                      <img
                        src="https://res.cloudinary.com/djwfu7z21/image/upload/v1730533223/createblog_bnc3hq.svg"
                        alt="Upload Icon"
                        className={`${image ? "hidden" : "block"}`} // Hide icon if image is uploaded
                      />
                      <span>{image ? "Change image" : "Upload an image"}</span>
                    </div>
                  </label>

                  {/* Display the uploaded image inside the main block */}
                  {image && (
                    <div className="mt-10">
                      <img
                        src={URL.createObjectURL(image)} // Generate preview URL for the image file
                        alt="Uploaded"
                        className="max-w-[80%] max-h-[300px] rounded-lg mt-4 mx-auto" // Image size and border style
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Blog Title */}
              <p className="flex justify-start ml-52 text-lg mt-12">
                Blog Heading
              </p>
              <div className="mb-6 w-[901px] h-[97px] mx-auto">
                <input
                  type="text"
                  placeholder="Enter Blog title....."
                  className="w-[900px] p-4 border border-gray-300 rounded-lg"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              {/* Author Name */}
              <p className="flex justify-start ml-52 text-lg ">Author Name</p>

              <div className="mb-6 w-[901px] h-[97px] mx-auto">
                <input
                  type="text"
                  placeholder="Name of the author"
                  className="w-[900px] p-4 border border-gray-300 rounded-lg"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>

              {/* Category Input */}
              <div className="mb-6 w-[901px] h-[97px] mx-auto">
                <label className="block mb-2 text-lg">
                  Select Blog Category
                </label>
                <select
                  className="w-[900px] p-4 border border-gray-300 rounded-lg"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categories.map((item) => {
                    return (
                      <option key={item.name} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* Blog Content */}
              <div className="mb-6 flex justify-center">
                <div className="w-full max-w-5xl">
                  <label className="block mb-2 text-lg text-center">
                    Compose the blog content
                  </label>
                  {/* React quill Text editor */}
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={content}
                    onChange={setContent}
                    className="rounded-lg w-full bg-white"
                    required
                  />
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-center gap-4 items-center mt-8">
                <button
                  type="submit"
                  className=" text-[#03045E] border border-[#03045E] shadow-xl px-6 py-3 rounded-md hover:shadow-2xl transition-colors"
                  disabled={loading}
                  onClick={handlePublishClick}
                >
                  {loading ? "Publishing..." : "Publish"}
                </button>

                <button
                  type="button"
                  className="flex items-center space-x-1 bg-gray-200 px-4 py-3 rounded-md hover:bg-gray-300 transition-colors"
                  onClick={() => {
                    setPreview(true);
                  }}
                >
                  <FaEye size={16} />
                  <span>Watch Preview</span>
                </button>
                <button
                  type="button"
                  className="bg-red-600 text-white px-6 py-3 rounded-md hover:bg-red-500 transition-colors"
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
              {/* Confirmation Popup */}
              {showPopup && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                  <div className="bg-white p-6 rounded-md shadow-xl max-w-sm w-full">
                    <h3 className="text-lg font-semibold">Are you sure?</h3>
                    <p className="mt-2">
                      Do you want to proceed with this action?
                    </p>
                    {!cancel && (
                      <div className="mt-4 flex justify-end gap-4">
                        <button
                          className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400"
                          onClick={() => handleConfirm("cancel")}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                          onClick={() => handleConfirm("publish")}
                        >
                          Confirm Publish
                        </button>
                      </div>
                    )}
                    {cancel && (
                      <div className="mt-4 flex justify-end gap-4">
                        <button
                          className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400"
                          onClick={() => handleConfirm("cancel")}
                        >
                          Dismiss
                        </button>
                        <button
                          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                          onClick={() => navigate("/blog")}
                        >
                          cancel
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
          {/* Custom footer  */}
          <section className="">
            <div className="flex flex-col gap-8 items-start p-14 bg-[rgb(243,240,240)]">
              <h1 className="text-[#1C5CFF] text-[20px] ">Stay up to date</h1>
              <p className="text-black font-bold text-[36px]">
                Join our Newsletter
              </p>
              <div className="flex flex-row gap-4 ml-24 items-center text-center">
                <input
                  className="w-[267.5px] h-[32.77px] rounded-none border-none p-2"
                  id="email1"
                  type="email"
                  placeholder="Enter your email"
                  required
                />
                <button className="w-[103.18px] h-[32.77px] bg-transparent text-[16px] font-bold border-[1px] border-gray-300 text-center">
                  Get started
                </button>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default CreateBlog;
