import { Route } from "react-router-dom";
import NotFound from "../pages/notFound/notFound";
import AboutUs from "../pages/aboutUs/aboutUs";
import Home from "../pages/home/home";
import Contact from "../pages/contact/contact";
import ThankYou from "../pages/contact/thankYou";
import Sitemap from "../pages/sitemap/Sitemap";
import SitemapXML from "../pages/sitemap/SitemapXML";

const OtherRoutes = (
  <>
    <Route path="*" element={<NotFound />} />;
    <Route path="/" element={<Home />} />;
    <Route path="/about" element={<AboutUs />} />;
    <Route path="/contact" element={<Contact />} />;
    <Route path="/thank-you" element={<ThankYou />} />;
    <Route path="/sitemap" element={<Sitemap />} />
    <Route path="/sitemap.xml" element={<SitemapXML />} />
  </>
);

export default OtherRoutes;
