import React, { useState, useEffect } from "react";
import {
  ErrorToast,
  SuccessToast,
} from "../../components/common/toast/toast.js";

import BackButton from "../../components/common/BackButton.jsx";
import useApiRequest from "../../hooks/apiRequest.js";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader.jsx";

const Contact = () => {
  const { loading, apiRequest } = useApiRequest();

  const navigate = useNavigate();
  const subjects = [
    "General Inquiry",
    "Healthcare Services",
    "Insurance and Costs",
  ];

  const [selected, setSelected] = useState(subjects[0]);
  const initialFormData = {
    firstname: "",
    lastname: "",
    name: "",
    email: "",
    phone: "",
    subject: "General Inquiry",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const onChange = (field, value) => {
    // If phone number is being updated, we make sure to only allow digits.
    if (field === "phone") {
      // Ensure the phone number is 10 digits (only numeric input).
      const numericValue = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      if (numericValue.length <= 10) {
        setFormData((prevState) => ({
          ...prevState,
          [field]: numericValue, // Store only digits in phoneno field
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
    const phoneRegex = /^\d{10}$/; // Phone number validation for exactly 10 digits

    if (
      formData.firstname === "" ||
      formData.lastname === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.message === ""
    ) {
      ErrorToast("Please fill all the fields");
    } else if (!emailRegex.test(formData.email)) {
      ErrorToast("Please enter a valid email address");
    } else if (!phoneRegex.test(formData.phone)) {
      ErrorToast("Please enter a valid phone number with exactly 10 digits");
    } else {
      try {
        // Convert phoneno to an integer when sending the request and set name field to first name and last name
        const data = await apiRequest("POST", `/contact/save-contact`, {
          ...formData,
          phone: parseInt(formData.phone), // Convert phone number to integer
          name: `${formData.firstname} ${formData.lastname}`,
        });

        // Show success notification
        if (data.status === 200) {
          SuccessToast(
            data?.data || "Your message has been sent successfully!"
          );
          // Reset formData to its initial state
          setFormData(initialFormData);
          navigate("/thank-you");
        } else {
          ErrorToast(data?.data || "Failed to send message");
        }
      } catch (error) {
        console.error("Error saving contact:", error);
        // Show error notification
        ErrorToast(
          error.response?.data?.message ||
            "There was an error sending your message. Please try again."
        );
      }
    }
  };

  return (
    <div className="text-[#03045E] bg-[#EAE9E9] pb-12">
      <div className="ml-20 text-left flex gap-[525px]">
        <BackButton />
      </div>
      <div className="w-full text-center flex flex-col justify-center">
        <p className="text-4xl font-extrabold font-sans text-center">
          Contact Us
        </p>
        <p className="mt-5 text-lg ">
          Any question or remarks? Just write a message!
        </p>
      </div>
      <div className="bg-white w-[1050px] rounded-xl flex p-2 mx-auto mt-4">
        <div className="bg-[#EAE9E9] h-[500px] rounded">
          <h1 className="mx-auto w-[400px] text-3xl font-bold mt-7 flex justify-center mb-28">
            Contact Information
          </h1>
          <div className="flex ml-16 gap-3 mb-3">
            <img
              className="h-5 w-5"
              src="https://res.cloudinary.com/djwfu7z21/image/upload/v1731067380/Phone_qnprzg.svg"
              alt="img"
            />
            <p className="">+91 7488670774</p>
          </div>
          <div className="flex ml-16 gap-3 mb-3">
            <img
              className="h-5 w-5"
              src="https://res.cloudinary.com/djwfu7z21/image/upload/v1731067380/mail_mxkiy2.svg"
              alt="img"
            />
            <p className="">samridh@clinixsphere.in</p>
          </div>
          <div className="flex ml-16 gap-3">
            <img
              className="h-5 w-5"
              src="https://res.cloudinary.com/djwfu7z21/image/upload/v1731067380/location_nkkk1d.svg"
              alt="img"
            />
            <p className="">Gurgaon, Haryana</p>
          </div>
        </div>
        <div className="mt-12 mx-auto">
          <div className="flex gap-10">
            <div>
              <p>First Name</p>
              <input
                className="border-none focus:outline-none h-10 w-64"
                type="text"
                value={formData.firstname}
                onChange={(e) => onChange("firstname", e.target.value)}
              />
              <div className="h-[1px] w-64 border border-[#03045E]"></div>
            </div>

            <div>
              <p>Last Name</p>
              <input
                className="border-none focus:outline-none h-10 w-64"
                type="text"
                value={formData.lastname}
                onChange={(e) => onChange("lastname", e.target.value)}
              />
              <div className="h-[1px] w-64 border border-[#03045E]"></div>
            </div>
          </div>
          <div className="flex gap-10 mt-8">
            <div className="">
              <p>Email</p>
              <input
                className="border-none focus:outline-none h-10 w-64"
                type="email"
                value={formData.email}
                onChange={(e) => onChange("email", e.target.value)}
              />
              <div className="h-[1px] w-64 border border-[#03045E]"></div>
            </div>

            <div>
              <p>Phone Number</p>
              <input
                className="border-none focus:outline-none h-10 w-64"
                type="text"
                value={formData.phone}
                onChange={(e) => onChange("phone", e.target.value)} // Handle onChange for phone number
                maxLength="10" // Limit input length to 10 characters
              />
              <div className="h-[1px] w-64 border border-[#03045E]"></div>
            </div>
          </div>
          <p className="w-full text-xl font-bold mt-8 mb-3">Select Subject?</p>
          <div className="flex space-x-6  border-blue-300 pl-6">
            {subjects.map((subject, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelected(subject);
                  onChange("subject", subject);
                }}
                className={`flex items-center text-sm cursor-pointer space-x-2 ${
                  selected === subject
                    ? "text-blue-900 font-semibold"
                    : "text-gray-400"
                }`}
              >
                <span
                  className={`h-4 w-4 rounded-full  border-2 ${
                    selected === subject
                      ? "bg-blue-900 border-blue-900"
                      : "border-gray-400"
                  }`}
                />
                <span>{subject}</span>
              </div>
            ))}
          </div>
          <p className="mt-5">Message</p>
          <input
            type="text"
            className="border-none focus:outline-none h-10 w-[450px]"
            placeholder="Write your message"
            value={formData.message}
            onChange={(e) => onChange("message", e.target.value)}
          />
          <div className="h-[1px] w-[450px] border border-[#03045E]"></div>
          <div className="flex justify-center mt-7">
            <button
              disabled={loading}
              onClick={handleSubmitForm}
              className="h-12 w-40 bg-white text-[#03045E] font-semibold rounded-lg shadow-md hover:bg-indigo-50 focus:outline-none focus:ring-2  transition ease-in-out duration-200"
            >
              {loading ? <Loader marginY="my-0" /> : "Send Message"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
