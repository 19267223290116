import React from "react";
import { Link } from "react-router-dom";

const ThankYou = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 text-center p-4">
      <div className="bg-white p-10 rounded-lg shadow-2xl max-w-lg w-full">
        <h1 className="text-4xl font-extrabold text-blue-900 mb-6">
          Thank You!
        </h1>
        <p className="text-gray-700 text-lg mb-8">
          Your message has been registered successfully! We'll get back to you
          soon.
        </p>
        <Link to="/">
          <button className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-500 transition duration-300 shadow-lg transform hover:scale-105">
            Go to Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ThankYou;
