import React from "react";

const Sitemap = () => {
  const pages = [
    { href: "https://www.clinixsphere.in/", text: "Home" },
    { href: "https://www.clinixsphere.in/about", text: "About Us" },
    { href: "https://www.clinixsphere.in/features", text: "Features" },
    { href: "https://www.clinixsphere.in/contact", text: "Contact Us" },
  ];

  return (
    <div>
      <h1>Clinix Sphere Sitemap</h1>
      <ul>
        {pages.map((page, index) => (
          <li key={index}>
            <a href={page.href}>{page.text}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sitemap;
