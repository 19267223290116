import React, { useEffect, useState } from "react";
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa";
import OTPInput from "react-otp-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../components/common/toast/toast";
import { countryCodes } from "../../components/common/countryCodes";
import { useDispatch, useSelector } from "react-redux";
import useApiRequest from "../../hooks/apiRequest";
import {
  signInStart,
  signInSuccess,
  signInFailure,
} from "../../redux/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import Loader from "../../components/common/Loader";
import GoogleAuth from "../../components/auth/signUpWithGoogle";

const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { apiRequest, loading } = useApiRequest();

  //redux
  const { currentUser } = useSelector((state) => state.auth);

  //check token validation
  const token = Cookies.get("clinix-auth-token");
  const isTokenValid = (token) => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      return decoded.exp > Date.now() / 1000; // Token is valid if exp is in the future
    } catch (error) {
      console.error("Token decoding failed:", error);
      return false;
    }
  };

  const [tab, setTab] = useState("email"); // Set default tab to "email"
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
  const [signInSuccessful, setSignInSuccessful] = useState(false);
  const [selectedCode, setSelectedCode] = useState(
    countryCodes.countries[0].code
  ); //country code

  //Form data to signin with email and phone
  const [signInWithEmailFormData, setSignInWithEmailFormData] = useState({
    loginMethod: "",
    password: "",
    email: "",
  });

  const [signInWithPhoneFormData, setSignInWithPhoneFormData] = useState({
    loginMethod: "",
    phoneNumber: "",
  });

  // Fetch tab from URL search params
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get("tab");
    if (tabFromUrl) setTab(tabFromUrl);
  }, [location.search]);

  //for email on change set values
  const handleInputChangeForEmail = (e) => {
    const { name, value } = e.target;
    setSignInWithEmailFormData((prev) => ({
      ...prev,
      [name]: value,
      loginMethod: "email",
    }));
  };
  //handle signin in with email
  const handleSignInWithEmail = async (e) => {
    e.preventDefault();

    const { email, password } = e.target.elements;

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.value)) {
      return ErrorToast("Please enter a valid email address");
    }

    // Basic field validation
    if (!email.value || !password.value) {
      return ErrorToast("Please fill all the required fields");
    }
    try {
      dispatch(signInStart());
      const res = await apiRequest(
        "POST",
        "/userMain/login",
        signInWithEmailFormData
      );
      const token = res.data.jwt; //take token from response

      // Check if token exists in the response
      if (token) {
        try {
          const decoded = jwtDecode(token); //decode token to extract information to place it in redux persist
          const data = {
            id: decoded.id,
            name: decoded.name,
            email: decoded.email,
            isAdmin: decoded.isAdmin,
          };

          dispatch(signInSuccess(data)); // Dispatch decoded data to Redux

          Cookies.set("clinix-auth-token", token); // Place token in cookies

          SuccessToast("SignIn successful");
          setSignInSuccessful(true);
        } catch (error) {
          console.error("Token decoding failed:", error);
          ErrorToast("Sign-in failed due to token error.");
          dispatch(signInFailure());
        }
      }
    } catch (error) {
      console.error(error);
      ErrorToast(error.response?.data?.message || "Error in signin");
    }
  };

  //for phone on change set values
  const handleInputChangeForPhone = (e) => {
    const { name, value } = e.target;
    setSignInWithPhoneFormData((prev) => ({
      ...prev,
      [name]: name === "phoneNumber" ? `${selectedCode}${value}` : value,
      loginMethod: "mobile",
    }));
  };

  //send otp with phone number
  const handleSignInWithPhone = async (e) => {
    e.preventDefault();

    const { phoneNumber } = e.target.elements;

    // Basic field validation
    if (!phoneNumber.value) {
      return ErrorToast("Please fill all the required fields");
    }

    // Phone number format validation for a 10-digit number
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phoneNumber.value)) {
      return ErrorToast("Please enter a valid 10-digit phone number");
    }

    try {
      const res = await apiRequest(
        "POST",
        "/userMain/login",
        signInWithPhoneFormData
      );
      SuccessToast(res?.data?.message || "Otp sent");
      navigate(
        `/auth/signin?tab=otpsent&phoneNumber=${selectedCode}${phoneNumber.value}&name=}`
      );
    } catch (error) {
      console.error(error);
      ErrorToast(error.response?.data?.message || "Error in sending otp");
    }
  };
  // Handle OTP submission
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(location.search);
    const phoneNumberFromUrl = urlParams.get("phoneNumber");

    const data = {
      phoneNumber: `+${phoneNumberFromUrl.trim()}`,
      otp: otp.toString(),
    };

    try {
      dispatch(signInStart());
      const res = await apiRequest("POST", "/userMain/verify-otp", data);

      // Decoding token to get data and store data in redux persist
      const token = res.data.token;
      // Check if token exists in the response
      if (token) {
        try {
          const decoded = jwtDecode(token); //decode token to extract information to place it in redux persist
          const data = {
            id: decoded.id,
            name: decoded.name,
            email: decoded.email,
            isAdmin: decoded.isAdmin,
          };

          dispatch(signInSuccess(data)); // Dispatch decoded data to Redux

          Cookies.set("clinix-auth-token", token); // Place token in cookies

          SuccessToast("SignIn successful");
          setSignInSuccessful(true);
        } catch (error) {
          console.error("Token decoding failed:", error);
          ErrorToast("Sign-in failed due to token error.");
          dispatch(signInFailure());
        }
      }
    } catch (error) {
      console.error(error);
      ErrorToast(error.response?.data?.message || "Error in OTP verification");
    }
  };

  //handle resend otp
  const handleResendOtp = async () => {
    const urlParams = new URLSearchParams(location.search);
    const phoneNumberFromUrl = urlParams.get("phoneNumber");
    const name = urlParams.get("name");
    const data = {
      name,
      phoneNumber: "+" + phoneNumberFromUrl.trim(),
      loginMethod: "mobile",
    };

    try {
      const res = await apiRequest("POST", "/userMain/login", data);
      SuccessToast(res?.data?.message || "Otp sent");
    } catch (error) {
      console.error(error);
      ErrorToast(error.response?.data?.message || "Error in sending otp");
    }
  };

  // Sign in with Email component
  const signInWithEmail = (
    <div>
      <p className="mb-4 text-[16px] text-black opacity-80 text-left">
        Enter your credentials to create your account
      </p>
      <form onSubmit={handleSignInWithEmail}>
        <div className="mb-4">
          <label className="block text-gray-700 text-left font-[500] text-[14px]">
            Email address
          </label>
          <input
            onChange={handleInputChangeForEmail}
            type="email"
            name="email"
            placeholder="Enter your email"
            className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10"
          />
        </div>
        <div className="mb-4 relative">
          <div className="flex justify-between">
            <label className="block text-gray-700 text-left font-[500] text-[14px]">
              Password
            </label>
            <button
              type="button"
              onClick={() => {
                navigate("/auth/forgotpassword");
              }}
              className="block text-[#0C2A92] cursor-pointer text-left font-[500] text-[14px]"
            >
              Forgot password?
            </button>
          </div>
          <input
            onChange={handleInputChangeForEmail}
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Enter password"
            className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10"
          />

          {/* Eye icon for showing/hiding password */}
          <div className="absolute right-4 top-9 text-black cursor-pointer">
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>

        <div className="mb-6 flex items-center">
          <input
            type="checkbox"
            id="remember"
            className="mr-2"
            name="remember"
          />
          <label htmlFor="remember" className="text-black">
            Remember me
          </label>
        </div>
        <button
          type="submit"
          className="w-full bg-[#3D74FF] text-white py-2 rounded-2xl font-[700]"
          disabled={loading}
        >
          {loading ? <Loader marginY="1" /> : <p> Sign In</p>}
        </button>
      </form>
    </div>
  );

  // Sign in with Phone Number component
  const signInWithPhone = (
    <form onSubmit={handleSignInWithPhone}>
      <div className="mt-10 mb-4">
        <label className="block text-gray-700 text-left font-[500] text-[14px]">
          Country Code
        </label>
        <select
          value={selectedCode}
          onChange={(e) => setSelectedCode(e.target.value)}
          className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10"
        >
          {countryCodes.countries.map((country) => (
            <option key={country.name} value={country.code}>
              {country.name} ({country.code})
            </option>
          ))}
        </select>
      </div>
      <div className="mt-10 mb-4">
        <label className="block text-gray-700 text-left font-[500] text-[14px]">
          Phone Number
        </label>
        <input
          name="phoneNumber"
          onChange={handleInputChangeForPhone}
          type="text"
          placeholder="Enter your phone number"
          className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10"
        />
      </div>
      <button
        type="submit"
        className="w-full bg-[#3D74FF] text-white py-2 rounded-2xl font-[700]"
        disabled={loading}
      >
        {loading ? <Loader marginY="1" /> : <p> Send OTP</p>}
      </button>
    </form>
  );

  //Component when otp is sent
  const enterCode = (
    <div className="text-black flex flex-col gap-10 my-4">
      <p className="w-[343px] text-left text-[20px] font-[600]">
        Enter 6 digit verification code sent to your phone number
      </p>
      {/* Enter Otp box  */}
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span>{"  "}</span>}
        renderInput={(props) => <input {...props} />}
        containerStyle={{
          width: "343px",
          display: "flex",
          justifyContent: "space-between",
        }}
        inputStyle={{ width: "50px", height: "50px" }}
      />
      {/* Resend otp visible when otp is sent */}
      <p className="text-[16px] font-[600] text-left">
        Didn{"'"}t receive a code?{" "}
        <span className="text-[#FF8682] cursor-pointer">
          <button type="button" onClick={handleResendOtp}>
            Resend
          </button>
        </span>
      </p>

      {/* Submit button  */}
      <button
        type="button"
        onClick={handleOtpSubmit}
        className="w-full bg-[#3D74FF] text-white py-2 rounded-2xl font-[700] "
        disabled={loading}
      >
        {loading ? <Loader marginY="1" /> : <p>Submit</p>}
      </button>
    </div>
  );

  //SignIn success
  const signInSucessComponent = (
    <div className="felx flex-col justify-center my-8">
      <div className="relative flex items-center justify-center">
        {/* Circle */}
        <svg
          className="h-[106px] w-[106px]"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="#69C984"
            strokeWidth="0.5"
          ></circle>
        </svg>
        {/* Checkmark */}
        <p className="absolute text-[#69C984] text-2xl flex items-center justify-center">
          <FaCheck />
        </p>
      </div>

      <div className="flex flex-col gap-10 justify-center text-center">
        {/* Message  */}
        <p className="font-[700] text-[22px] text-black">Success!</p>
        <p className="text-[18px] text-[#B6B6B6] w-[300px] mx-auto">
          Congratulations! You have been successfully authenticated
        </p>
        {/* Button to continue  */}
        {/* Submit button  */}
        <button
          onClick={() => {
            navigate("/");
          }}
          type="button"
          className="w-[367px] mx-auto bg-[#69C984] text-white py-2 rounded-2xl font-[700] mt-6"
        >
          Continue
        </button>
      </div>
    </div>
  );

  return (
    <>
      {currentUser && isTokenValid(token) ? (
        <Navigate to="/" />
      ) : (
        <div className="bg-white p-4 min-h-screen flex justify-center">
          {signInSuccessful ? (
            signInSucessComponent
          ) : (
            <div className="flex justify-center">
              <div className="bg-white rounded-lg p-8 w-full max-w-4xl flex">
                {/* Left Side: Form */}
                <div className="w-1/2">
                  {/* Tabs for Email and Phone Number */}
                  <div className="flex mb-6 font-semibold w-[320px] h-[52px]">
                    <button
                      className={`w-1/2 py-2 text-center rounded-l-lg border ${
                        tab === "email"
                          ? "bg-blue-500 text-white"
                          : "bg-white text-gray-700"
                      }`}
                      onClick={() => navigate("/auth/signin?tab=email")}
                    >
                      Email
                    </button>
                    <button
                      className={`w-1/2 py-2 text-center rounded-r-lg border ${
                        tab === "phone" || tab === "otpsent"
                          ? "bg-blue-500 text-white"
                          : "bg-white text-gray-700"
                      }`}
                      onClick={() => navigate("/auth/signin?tab=phone")}
                    >
                      Phone Number
                    </button>
                  </div>
                  <h1 className="text-[32px] mb-2 text-left text-black font-[500]">
                    Sign In
                  </h1>

                  {/* Form for Email */}
                  {tab === "email" && signInWithEmail}
                  {/* Form for Phone */}
                  {tab === "phone" && signInWithPhone}
                  {/* Enter otp form  */}
                  {tab === "otpsent" && enterCode}

                  {/* Separator */}
                  <div className="flex items-center justify-between mt-4">
                    <div className="border-t w-2/5 border-gray-300"></div>
                    <span className="text-black">Or</span>
                    <div className="border-t w-2/5 border-gray-300"></div>
                  </div>

                  {/* Signup with google button  */}
                  <GoogleAuth />

                  <p className="mt-6 text-center text-black font-semibold">
                    Don{"'"}t have an account?{" "}
                    <button
                      type="button"
                      onClick={() => {
                        navigate("/auth/signup");
                      }}
                      className="text-blue-500"
                    >
                      Sign Up
                    </button>
                  </p>
                </div>

                {/* Right Side: Image */}
                <div className="w-1/2 flex justify-center items-center pl-32 pb-20">
                  <img
                    src="https://res.cloudinary.com/djwfu7z21/image/upload/v1730902482/auth-1_mh9pfo.png"
                    alt="SignIn"
                    className="w-[30rem] h-[30rem] object-cover"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SignIn;
